<template>
  <v-row>
    <v-col cols="12" :sm="options.square ? 4 : 6">
      <vx-img
        :square="options.square"
        :rectangle="!options.square"
        :src="content.thumbnail || $getVenueImage()"
        placeholder
        @click="$emit('click')"
      />
    </v-col>

    <v-col cols="12" :sm="options.square ? 8 : 6">
      <v-list dense class="py-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Title</v-list-item-subtitle>
            <v-list-item-title>{{ content.meta?.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="content.meta?.site">
          <v-list-item-content>
            <v-list-item-subtitle>Source</v-list-item-subtitle>
            <v-list-item-title>{{ content.meta?.site }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <vx-btn outlined color="primary" @click="$emit('upload')"> Upload alternative thumbnail </vx-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="options.displayInLocation">
          <v-list-item-content>
            <v-list-item-subtitle>Display this content on location </v-list-item-subtitle>
            <v-list-item-title>
              <vx-input type="checkbox" hide-details :value="!!content.locationId" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="options.showBookings && bookings.length && content.isCreatedInTutti">
          <v-list-item-content>
            <v-list-item-subtitle>
              <div>If yes, please share which Tutti listings helped you create this content?</div>
            </v-list-item-subtitle>
            <v-list-item-title>
              <vx-input
                v-model="content.bookingId"
                type="autocomplete"
                hide-details
                :items="bookings"
                :item-text="getItemText"
                item-value="_id"
                @change="$emit('updateContent', content)"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="options.showBookings && content.isCreatedInTutti">
          <v-list-item-content>
            <v-list-item-subtitle>
              <div>Didn’t book the space through Tutti? Copy/paste the URL of the space here</div>
            </v-list-item-subtitle>
            <v-list-item-title>
              <vx-input v-model="content.venueUrl" type="text" hide-details @change="$emit('updateContent', content)" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { YES_NO_ITEMS } from '@tutti/constants';

export default {
  name: 'ContentInfo',

  props: {
    content: { type: Object, required: true },
    loading: { type: Object, required: true },
    bookings: { type: Array, required: true },
    options: { type: Object, required: true },
  },

  data() {
    return {
      YES_NO_ITEMS,
    };
  },

  methods: {
    getItemText(item) {
      return item.spaceId ? `${item.locationId?.name}: ${item.spaceId?.name}` : item.locationId?.name;
    },

    async onChangeVenueUrl() {
      try {
        const response = await axios.head(this.content.venueUrl);
        if (response) {
          this.$emit('updateContent', this.content);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
