var render = function () {
  var _vm$content$meta, _vm$content$meta2, _vm$content$meta3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.options.square ? 4 : 6
    }
  }, [_c('vx-img', {
    attrs: {
      "square": _vm.options.square,
      "rectangle": !_vm.options.square,
      "src": _vm.content.thumbnail || _vm.$getVenueImage(),
      "placeholder": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": _vm.options.square ? 8 : 6
    }
  }, [_c('v-list', {
    staticClass: "py-0",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Title")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$content$meta = _vm.content.meta) === null || _vm$content$meta === void 0 ? void 0 : _vm$content$meta.title))])], 1)], 1), (_vm$content$meta2 = _vm.content.meta) !== null && _vm$content$meta2 !== void 0 && _vm$content$meta2.site ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Source")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$content$meta3 = _vm.content.meta) === null || _vm$content$meta3 === void 0 ? void 0 : _vm$content$meta3.site))])], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-action', [_c('vx-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('upload');
      }
    }
  }, [_vm._v(" Upload alternative thumbnail ")])], 1)], 1), _vm.options.displayInLocation ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Display this content on location ")]), _c('v-list-item-title', [_c('vx-input', {
    attrs: {
      "type": "checkbox",
      "hide-details": "",
      "value": !!_vm.content.locationId
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.options.showBookings && _vm.bookings.length && _vm.content.isCreatedInTutti ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_c('div', [_vm._v("If yes, please share which Tutti listings helped you create this content?")])]), _c('v-list-item-title', [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "hide-details": "",
      "items": _vm.bookings,
      "item-text": _vm.getItemText,
      "item-value": "_id"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('updateContent', _vm.content);
      }
    },
    model: {
      value: _vm.content.bookingId,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "bookingId", $$v);
      },
      expression: "content.bookingId"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.options.showBookings && _vm.content.isCreatedInTutti ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', [_c('div', [_vm._v("Didn’t book the space through Tutti? Copy/paste the URL of the space here")])]), _c('v-list-item-title', [_c('vx-input', {
    attrs: {
      "type": "text",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('updateContent', _vm.content);
      }
    },
    model: {
      value: _vm.content.venueUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "venueUrl", $$v);
      },
      expression: "content.venueUrl"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }